//import * as bootstrap from 'bootstrap'; // import { Button, Carousel, Collapse, Dropdown, Tab } from 'bootstrap';

// import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

// import * as slick from 'slick-carousel/slick/slick.js';

import '../scss/style.scss';

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

// Set a Cookie
function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

/*function deleteCookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}*/


$( document ).ready(function() {
    document.addEventListener('om.Campaign.afterShow', function(event) {
        if (event.detail.Campaign.id == 'yqaasrwn10udne5rtage' || event.detail.Campaign.id == 'baa8gx27phjywa1oaj7j') // your slug
        {
            var campaignID = 'om-'+ event.detail.Campaign.id;
            var campaignHeight = document.getElementById(campaignID).offsetHeight;
            var fixedElement = document.getElementById('header'); //give ID for your fixed element OR document.querySelector('.yourClassName.yourSecondClassName');
            fixedElement.style.top = campaignHeight + 'px';
            fixedElement.classList.remove('top-0');
        }
    });
    document.addEventListener('om.Campaign.close', function(event) {
        var fixedElement = document.getElementById('header'); //give ID for your fixed element or document.querySelector('.yourClassName.yourSecondClassName');
        fixedElement.style.top = 0;
    });

    let copyCode = document.querySelectorAll('code, .ql-code-block, .ql-code-block-container').forEach(element => {
        element.addEventListener('click', () => {
            let text = element.textContent;
            navigator.clipboard.writeText(`${text}`);
            element.classList.add('copied');
            setTimeout(function () {
                element.classList.remove('copied');
            }, 2.0 * 1000);
        });
    });

    /*var hoverTimeout;
    var element;

    $('code, .ql-code-block, .ql-code-block-container').mouseenter(function () {
       $(this).addClass('hover');
       if (element == this) {
        clearTimeout(hoverTimeout);
       }
     });

    $('code, .ql-code-block, .ql-code-block-container').mouseleave(function () {
        element = this;
            $(element).removeClass('hover');
        hoverTimeout = setTimeout(function () {
        }, 0.5 * 1000);
     });*/

    /*if (findGetParameter('geot_debug') != null) {
        deleteCookie('authorized');
    }*/
    if (getCookie("authorized") === undefined) { // if it's not set, we ask for it
        fetch('https://www.myexcelonline.com/wp-json/geolocate/v1/current')
          .then(response => response.json())
          .then(response => {
            setCookie("authorized", response.authorized, 180);

            let authorized = getCookie("authorized");
            authorized = /^true$/i.test(authorized);
            if (authorized) { // if authorized
                $('#header-cta').addClass('d-xl-block');
                $('.show-on-authorized').removeClass('d-none').addClass('d-block');
                $('.show-on-authorized-lg').addClass('d-lg-block');
            }
          })
          .catch(error => {
            setCookie("authorized", false, 1);
          });
    }
    else {
        let authorized = getCookie("authorized");
        authorized = /^true$/i.test(authorized);
        if (authorized) { // if authorized
            $('#header-cta').addClass('d-xl-block');
            $('.show-on-authorized').removeClass('d-none').addClass('d-block');
            $('.show-on-authorized-lg').addClass('d-lg-block');
        }        
    }

    let carousels = document.querySelectorAll('.carousel-multiple');
    carousels.forEach((carousel) => {

        let items = carousel.querySelectorAll('.carousel-item');

        items.forEach((el) => {
            const minPerSlide = carousel.dataset.slides;
            let next = el.nextElementSibling;
            for (var i=1; i<minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = items[0];
                }
                let cloneChild = next.cloneNode(true);
                el.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });

    });

	if ($('body.single-post').length > 0) {
    	$('.entry-content p span strong:contains(STEP)').addClass('d-none').parents('p').addClass("step");
        $('.entry-content p strong span:contains(STEP)').addClass('d-none').parents('p').addClass("step");
        $('.entry-content p > strong:contains(STEP)').addClass('d-none').parents('p').addClass("step");
	}

	if ($('body.category').length > 0) {
        $('.entry-content span strong:contains(STEP)').addClass('d-none').parents('span').addClass("step");
    	$('.entry-content strong span:contains(STEP)').addClass('d-none').parents('strong').addClass("step");
	}

    $('.scroll-to-download').on('click', function(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });

     $('#scroll-to-overview').on('click', function(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        window.scrollTo({ top: ($('#overview').offset().top - 110), behavior: 'smooth' });
    });

    if ($('#entry-title').length > 0) {
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();

            if (scroll >= 100) {
                $("#header").addClass("is-sticky");
            } else {
                $("#header").removeClass("is-sticky");
            }
        });
        $(window).trigger('scroll');
    }

    $('.button-toggle-toc').on('click', function(evt) {
        $('.col-sidebar-start').toggleClass('open');
    });

    $('.ez-toc-widget-sticky-list li, .ez-toc-widget-sticky-list-manual li a').on('click', function(evt) {
        $('.col-sidebar-start').removeClass('open');
    });

    //-----------------------------------------------------------------------
    // Search
    //-----------------------------------------------------------------------
    $('.menu-item-search').click(function(e) {
        $(this).addClass('open');
    });
    $('.btn-close').click(function(e) {
        $('#category-list li:has(img[value=0])').click();
        $(this).parents('.menu-item-search').removeClass('open');
        $('.search-results-ajax').removeClass('ajax-ok');
        //$('.lang-select .btn-select').val('0');
        $('.search-form .search-field').val('');
        $(".category-list-wrapper").removeClass('show');
        e.preventDefault();
        e.stopPropagation();
    });
    $('.search-close').click(function(e) {
        $('.search-container').fadeOut('fast');
        $('.menu-item-search').removeClass('open');
        e.preventDefault();
    });
    $('.search-menu').click(function (e) {
        $('.search-container').fadeIn('fast');
        e.preventDefault();
    });
    $('.search-field').bind('keyup', function () {
        clearTimeout( $(this).data('timer'));
        var that = this;

        var timer = setTimeout(function() {
            var search_text = $(that).val();
            var cat = $('.lang-select .btn-select').val();
            if (search_text.length >= 3) {
            	$('.search-form .lds-ellipsis').addClass('ajax-searching');
                searchAjax(search_text, cat);
            }
            else {
                $('.search-results-ajax').removeClass('ajax-ok');
            }
        }, 500);

        $(this).data('timer', timer);
    });
    function searchAjax(term, category) {
        $.ajax({
            url: ajaxurl,
            type: 'post',
            data: {
                action: 'search_predictive',
                s: term,
                cat: category
            },
            success: function (response) {
                if(response == 'no_results') {
                    $('.search-results-ajax').removeClass('ajax-ok');
                } else {
                    $('.search-results-ajax').html(response);
                    $('.search-results-ajax').addClass('ajax-ok');
                }
                $('.search-form .lds-ellipsis').removeClass('ajax-searching');                    
            }
        });
    }
    
    //-----------------------------------------------------------------------
    // Shortcode table of contents
    //-----------------------------------------------------------------------
    if($('#table_of_contents').length) {

        $('#table_of_contents a').click(function(e) {
            var target_scroll_id = $(this).attr('href');
            var target_scroll_offset = $(target_scroll_id).offset();
            var scroll_distance = target_scroll_offset.top;
            var gap = 120;
            $('html, body').animate({
                scrollTop: scroll_distance - gap
            }, 500);
            e.preventDefault();
        });

        $('a[href="#table_of_contents"').click(function(e) {
            var target_scroll_offset = $('#table_of_contents').offset();
            var scroll_distance = target_scroll_offset.top;
            var gap = 120;
            $('html, body').animate({
                scrollTop: scroll_distance - gap
            }, 500);
            e.preventDefault();
        });

    }

    $('#toggle-blog').on('change', function(evt) {
        if ($(this).is(':checked')) { // Office is selected
            $('#office-footer-carousel').removeClass('d-none').show();
            $('#office-categories').removeClass('d-none').show();
            $('#excel-footer-carousel').hide();
            $('#excel-categories').hide();
        }
        else { // Excel is selected
            $('#office-footer-carousel').hide();
            $('#office-categories').hide();
            $('#excel-footer-carousel').show(); 
            $('#excel-categories').show(); 
        }
        $('#toggle-popular').trigger('change');
    });

    $('#toggle-popular').on('change', function(evt) {
        if ($('#toggle-blog').is(':checked')) { // Office is selected
            if ($(this).is(':checked')) { // popular is selected
                $('#excel-carousel').hide();
                $('#excel-popular-carousel').hide();
                $('#office-carousel').hide();
                $('#office-popular-carousel').removeClass('d-none').show();
            }
            else { // recent is selected
                $('#excel-carousel').hide();
                $('#excel-popular-carousel').hide();
                $('#office-carousel').removeClass('d-none').show();
                $('#office-popular-carousel').hide();
            }
        }
        else { // Excel is selected
            if ($(this).is(':checked')) { // popular is selected
                $('#excel-carousel').hide();
                $('#excel-popular-carousel').removeClass('d-none').show();
                $('#office-carousel').hide();
                $('#office-popular-carousel').hide();
            }
            else { // recent is selected
                $('#excel-carousel').removeClass('d-none').show();
                $('#excel-popular-carousel').hide();
                $('#office-carousel').hide();
                $('#office-popular-carousel').hide();
            }
        }
    });

    $('h3:contains("Key Takeaways")').addClass('key-takeaways');
    $('h3:contains("Key takeaways")').addClass('key-takeaways');
});


//test for getting url value from attr
// var img1 = $('.test').attr("data-thumbnail");
// console.log(img1);

//test for iterating over child elements
var langArray = [];
$('.category-picker option').each(function() {
  var img = $(this).attr("data-thumbnail");
  var text = this.innerText;
  var value = $(this).val();
  var item = '<li data-text="' + text + '"><img src="'+ img +'" alt="" value="'+value+'" width="20" height="20" /><span>'+ text +'</span></li>';
  langArray.push(item);
})

$('#category-list').html(langArray);

//Set the button value to the first el of the array
$('.btn-select').html(langArray[0]);
$('.btn-select').attr('value', '0');

//change button stuff on click
$('#category-list li').click(function() {
   var img = $(this).find('img').attr("src");
   var value = $(this).find('img').attr('value');
   var text = $(this).data('text');
   var item = '<li data-text="' + text + '"><img src="'+ img +'" alt="" width="20" height="20" /><span>'+ text +'</span></li>';
  $('.btn-select').html(item);
  $('.btn-select').attr('value', value);
  $(".category-list-wrapper").toggleClass('show');
  //console.log(value);

  $(".category-picker").val(value).change();

  $('.search-field').trigger('keyup');
});

$('.search-field').on('focus', function(evt) {
    $(".category-list-wrapper").removeClass('show');
});

$(".btn-select").click(function(e) {
    $(".category-list-wrapper").toggleClass('show');
});

//check local storage for the lang
var sessionLang = localStorage.getItem('lang');
if (sessionLang){
  //find an item with value of sessionLang
  var langIndex = langArray.indexOf(sessionLang);
  $('.btn-select').html(langArray[langIndex]);
  $('.btn-select').attr('value', sessionLang);
} else {
   var langIndex = langArray.indexOf('ch');
  $('.btn-select').html(langArray[langIndex]);
  //$('.btn-select').attr('value', 'en');
}


